import i18n from "i18next";
import TextSignature from "text-signature";

import { getBaseURL } from "./url";
import { secondaryMarketSellRequestStatusConstants } from "../constants";
/**
 * @summary a helper to generate a random transactionKey
 * @returns
 */
export const transactionKey = () => Math.random().toString(36).substr(2, 16);
/**
 * @summary get's the CSS class name according to the status code
 * @param {String} expr Expression (0,1,2,3,4,5,6)
 * @returns {String} `className`
 */
export const getCSSClassName = (expr) => {
  switch (expr) {
    case 0:
      return "text-warning";
    case 1:
      return "text-secondary";
    case 2:
    case 4:
      return "text-success";
    case 3:
    case 5:
    case 6:
      return "text-danger";
    default:
      return "";
  }
};

export const getRebbonCampaignLabel = (expr) => {
  switch (expr) {
    case 0:
      return "ribbon-warning";
    case 1:
      return "ribbon-success";
    case 2:
    case 4:
      return "ribbon-success";
    case 3:
    case 5:
    case 6:
    case 7:
      return "ribbon-primary";
    default:
      return "";
  }
};

/**
 *
 * @param {String} expr
 * @returns {String} `Status`
 */
export const getStatusCodes = (expr) => {
  switch (expr) {
    case 0:
      return i18n.t("campaignStatus.draft");
    case 1:
      return i18n.t("campaignStatus.pending");
    case 2:
      return i18n.t("campaignStatus.active");
    case 3:
      return i18n.t("campaignStatus.declined");
    case 4:
      return i18n.t("campaignStatus.successful");
    case 5:
      return i18n.t("campaignStatus.unsuccessful");
    case 6:
      return i18n.t("campaignStatus.hidden");
    case 7:
      return i18n.t("campaignStatus.inactive");
    default:
      return "";
  }
};

export const getWalletStatusCodes = (expr) => {
  switch (expr) {
    case 0:
      return i18n.t("wallet.status.pending");
    case 1:
      return i18n.t("wallet.status.inProcess");
    case 2:
      return i18n.t("wallet.status.success");
    case 3:
      return i18n.t("wallet.status.failed");
    default:
      return "";
  }
};

export const defaultValue = (options, value) =>
  options ? options.find((option) => option.label === value) : "";
export const reactSelectStyle = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    minWidth: "242px",
    zIndex: 9999,
    border: "1px solid lightgray", // default border color
    "&:hover": { borderColor: "gray" }, // border style on hover
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#d46514" : "#fff",
  }),
};

export const dayConversion = (expr) => {
  switch (expr) {
    case "Yearly":
      return i18n.t("global.years");
    case "Quarterly":
      return i18n.t("global.quarters");
    case "Monthly":
      return i18n.t("global.month");
    case "Days":
      return i18n.t("global.days");
    default:
      break;
  }
};

/**
 * @summary Captilizes first alphabet of the given string
 * @param {String} text
 * @returns
 */

export const capitalize = (text) => {
  if (text !== undefined) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
};

export const setLanguageId = (langId) => {
  localStorage.setItem("selected_language_id", langId);
};

export const getLanguageId = () => {
  return localStorage.getItem("selected_language_id");
};

export const setLanguageCode = (langCode) => {
  localStorage.setItem("i18nextLng", langCode);
};

export const getLanguageCode = () => {
  const languageCode =
    localStorage.getItem("i18nextLng") !== null &&
    (localStorage.getItem("i18nextLng").startsWith("en") ||
      localStorage.getItem("i18nextLng") === "en-US" ||
      localStorage.getItem("i18nextLng") === "dev")
      ? "en"
      : localStorage.getItem("i18nextLng");
  return languageCode;
};

export const getUserTypeWiseDisplay = (
  userTypeObj,
  onlyAdminCreateCampaign,
  currentUser
) => {
  let userTypeWiseDisplay = {};
  userTypeWiseDisplay["investorDisplay"] = true;
  userTypeWiseDisplay["campaignOwnerDisplay"] = false;
  localStorage.setItem("displayData", JSON.stringify(userTypeWiseDisplay));
  if (onlyAdminCreateCampaign === "yes" && currentUser?.isAdmin) {
    userTypeWiseDisplay["investorDisplay"] = true;
    userTypeWiseDisplay["campaignOwnerDisplay"] = true;
    localStorage.setItem("displayData", JSON.stringify(userTypeWiseDisplay));
  }

  if (userTypeObj?.slug !== undefined && onlyAdminCreateCampaign !== "yes") {
    switch (userTypeObj?.slug) {
      case "investor":
        userTypeWiseDisplay["investorDisplay"] = true;
        userTypeWiseDisplay["campaignOwnerDisplay"] = false;
        localStorage.setItem(
          "displayData",
          JSON.stringify(userTypeWiseDisplay)
        );
        return userTypeWiseDisplay;
      case "campaign-owner":
        userTypeWiseDisplay["investorDisplay"] = false;
        userTypeWiseDisplay["campaignOwnerDisplay"] = true;
        localStorage.setItem(
          "displayData",
          JSON.stringify(userTypeWiseDisplay)
        );
        return userTypeWiseDisplay;
      default:
        userTypeWiseDisplay["investorDisplay"] = true;
        userTypeWiseDisplay["campaignOwnerDisplay"] = true;
        localStorage.setItem(
          "displayData",
          JSON.stringify(userTypeWiseDisplay)
        );
        return userTypeWiseDisplay;
    }
  }
  return userTypeWiseDisplay;
};

export const clearTwofactorAuthData = () => {
  document.querySelector("#mainSiteHeader").style.display = "";
  document.querySelector("#mainSiteFooter").style.display = "";
  localStorage.removeItem("twoFactorLoginStatus");
  localStorage.removeItem("twoFactorLoginQRLink");
  localStorage.removeItem("twoFactorLoginUserEmail");
};

export const getLanguageReplaceText = (
  taxonomy,
  languageText,
  textToReplace,
  defaultText
) => {
  return textToReplace
    ? i18n.t(`${languageText}`, {
        val1: textToReplace?.val1,
        val2: textToReplace?.val2,
        val3: textToReplace?.val3,
        val4: textToReplace?.val4,
        val5: textToReplace?.val5,
        PROJECT_SINGULAR: taxonomy?.projectSingular,
      })
    : defaultText;
};

export const getTermLength = (terms) => {
  switch (terms) {
    case "Yearly":
      return 1;
    case "Quarterly":
      return 4;
    case "Monthly":
      return 12;
    case "Days":
      return 365;
    default:
      return 0;
  }
};

export const getTermToQuater = (terms) => {
  switch (terms) {
    case "Yearly":
      return 4;
    case "Quarterly":
      return 1;
    case "Monthly":
      return 0.3335;
    case "Days":
      return 0.01095;
    default:
      return 0;
  }
};

export const getStripedHtml = (html) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const englishToArabicCount = (s) =>
  s.replace(/\d/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d]);
export const getPagenumberImg = (pageNumberText) => {
  const optionsParameter = {
    width: 150,
    height: 60,
    paddingX: 0,
    paddingY: 30,
    canvasTargetDom: ".js-canvasTargetDom",
    font: ["35px", "Noto Naskh Arabic"],
    color: "black",
    textString: pageNumberText ?? " ",
    customFont: {
      name: "Noto Naskh Arabic",
      url: "https://fonts.googleapis.com/css?family=Noto%20Naskh%20Arabic",
    },
  };
  let textSignature = new TextSignature(optionsParameter);
  textSignature.generateImage(optionsParameter);
  return textSignature?.imageData;
};

export const getAppUserId = () => {
  return localStorage.getItem("appUserId");
};

export const setAppUserId = (appUserId) => {
  localStorage.setItem("appUserId", appUserId);
};

export const removeAppUserId = () => {
  localStorage.removeItem("appUserId");
};

export const getAppRedirectURL = (path) => {
  return `${getBaseURL()}?redirect=${path}`;
};

// enableRestrictMapCountry - When this field is yes at that time Restrict Country apply or no means global search auto complete work
// restrictMapCountryList - In this filed get Country Restrict Array ["us", "ca"]
// Admin Side - Google Map Tab - Select Restrict Country and Add
export const getRestrictCountryList = (generalSettings) => {
  if (generalSettings?.enableRestrictMapCountry === "yes") {
    return generalSettings?.restrictMapCountryList?.map((item) => {
      return item["value"];
    });
  } else {
    return [];
  }
};

export const convertOembedToIframe = (htmlString) => {
  // Use a regular expression to find the oembed element in the HTML string
  const oembedRegex = /<oembed[^>]*>/g;
  const oembedMatch = htmlString.match(oembedRegex);
  // const imgRegex = /<img[^>]*>/g;
  // const imgMatches = htmlString.match(imgRegex);

  // If an oembed element was found, convert it to an iframe element
  if (oembedMatch) {
    let oembedUrl = oembedMatch[0].match(/url="([^"]*)"/)[1];
    if (oembedUrl.includes("youtube.com/watch?v=")) {
      oembedUrl = oembedUrl.replace(
        "youtube.com/watch?v=",
        "youtube.com/embed/"
      );
    }
    const iframeElement = `<div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
    <iframe 
      src="${oembedUrl}" 
      frameborder="0" 
      allow="accelerometer; autoplay; gyroscope; picture-in-picture" 
      allowfullscreen 
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    ></iframe>
  </div>`;
    htmlString = htmlString.replace(oembedRegex, iframeElement);
  }
  return htmlString;
};
export const processHtmlContent = (htmlString) => {
  // Helper to convert oEmbed to iframe
  const convertOembedToIframe = (htmlString) => {
    const oembedRegex = /<oembed[^>]*>/g;
    const oembedMatch = htmlString.match(oembedRegex);

    if (oembedMatch) {
      let oembedUrl = oembedMatch[0].match(/url="([^"]*)"/)[1];
      if (oembedUrl.includes("youtube.com/watch?v=")) {
        oembedUrl = oembedUrl.replace(
          "youtube.com/watch?v=",
          "youtube.com/embed/"
        );
      }
      const iframeElement = `<div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
        <iframe 
          src="${oembedUrl}" 
          frameborder="0" 
          allow="accelerometer; autoplay; gyroscope; picture-in-picture" 
          allowfullscreen 
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
        ></iframe>
      </div>`;
      htmlString = htmlString.replace(oembedRegex, iframeElement);
    }
    return htmlString;
  };

  // Helper to process image tags
  const processImagesInHtml = (htmlString) => {
    const imgRegex = /<img[^>]*>/g;
    const imgMatches = htmlString.match(imgRegex);

    if (imgMatches) {
      imgMatches.forEach((imgTag) => {
        const srcMatch = imgTag.match(/src="([^"]*)"/);
        const src = srcMatch ? srcMatch[1] : "";

        const styledImgTag = `<div style="display: flex; justify-content: center; margin: 1rem 0;">
          <img 
            src="${src}" 
            style="max-width: 100%; height: auto; border: 1px solid #ddd; border-radius: 5px;"
            alt="Image"
          />
        </div>`;

        htmlString = htmlString.replace(imgTag, styledImgTag);
      });
    }
    return htmlString;
  };

  // Apply both transformations
  htmlString = convertOembedToIframe(htmlString);
  htmlString = processImagesInHtml(htmlString);

  return htmlString;
};

export const getWindowDimentions = () => {
  return { width: window.innerWidth, height: window.innerHeight };
};

/* secondary market helper function starts */
export const sellRequestStatus = (status) => {
  switch (status) {
    case secondaryMarketSellRequestStatusConstants.PENDING:
      return i18n.t("secondaryMarket.status.pending");
    case secondaryMarketSellRequestStatusConstants.UNDERREVIEW:
      return i18n.t("secondaryMarket.status.underReview");
    case secondaryMarketSellRequestStatusConstants.APPROVED:
      return i18n.t("secondaryMarket.status.approved");
    case secondaryMarketSellRequestStatusConstants.REJECTED:
      return i18n.t("secondaryMarket.status.rejected");
    case secondaryMarketSellRequestStatusConstants.CLOSED:
      return i18n.t("secondaryMarket.status.closed");
    default:
      return "";
  }
};

export const sellRequestStatusClass = (status) => {
  switch (status) {
    case secondaryMarketSellRequestStatusConstants.PENDING:
      return "seller-ribbon-warning";
    case secondaryMarketSellRequestStatusConstants.UNDERREVIEW:
      return "seller-ribbon-warning";
    case secondaryMarketSellRequestStatusConstants.APPROVED:
      return "seller-ribbon-success";
    case secondaryMarketSellRequestStatusConstants.REJECTED:
      return "seller-ribbon-danger";
    case secondaryMarketSellRequestStatusConstants.CLOSED:
      return "seller-ribbon-success";
    default:
      return "seller-ribbon-warning";
  }
};
/* secondary market helper function ends */
